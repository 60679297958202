import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Nav, Navbar, Dropdown, Icon, Divider } from 'rsuite';
import screenSize from "../../utils/hooks/screenSize";

import {CentralContext} from "../../context/centralStore";
import authenticationService from '../../utils/services/authentication.service';

import styles from './index.module.css';
import {ReactComponent as NotificationsIcon} from "../../images/notification.svg";

const NavBar = props => {
  const { height, width, narrow, tiny } = screenSize();
  const [activeKey, setActiveKey] = useState();

  const userDropdown = (key, event) => {
    switch (key) {
      case "logout":
        authenticationService.logout();
        break;
      default:
        console.log("unhandled event", key);
    }
  }
  let iconSize = "2x";
  if(tiny){
    iconSize="lg";
  }

  return (
    <CentralContext.Consumer>
    {(context)=>(
      <Navbar {...props} appearance="inverse" >
          {!narrow && <Navbar.Header>
            <Link to="/" className={styles["navbar-brand"]}>
              Apnemo
            </Link>
          </Navbar.Header>}
          <Navbar.Body>
            <Nav onSelect={setActiveKey} activeKey={activeKey}>
              <Nav.Item eventKey="1" componentClass={Link} to="/home" icon={<Icon icon="home" size={(narrow)?iconSize:"lg"} />}>{(narrow)?"":"Home"}</Nav.Item>
              {/*<Nav.Item eventKey="2" componentClass={Link} to="/newsfeed" icon={<Icon icon="newspaper-o" />}>News Feed</Nav.Item>*/}
              <Nav.Item eventKey="3" componentClass={Link} to="/logbook" icon={<Icon icon="pencil" size={(narrow)?iconSize:"lg"} />}>{(narrow)?"":"Log Book"}</Nav.Item>
              <Nav.Item eventKey="4" componentClass={Link} to="/locations" icon={<Icon icon="map-marker" size={(narrow)?iconSize:"lg"} />}>{(narrow)?"":"Locations"}</Nav.Item>
              <Dropdown title={(narrow)?"":"More"} icon={<Icon icon="plus" size={(narrow)?iconSize:"lg"} />}>
                <Dropdown.Item eventKey="5">Events</Dropdown.Item>
                <Dropdown.Item eventKey="6">Market</Dropdown.Item>
                <Dropdown.Item eventKey="7">Support this project</Dropdown.Item>
              </Dropdown>
            </Nav>
            <Nav pullRight>
              {
                (context.user.loggedIn)
                  ?
                    <Dropdown title={(context.user.basic)?(!narrow)?context.user.basic.firstName:"":"error"} icon={<Icon icon="user" size={(narrow)?iconSize:"lg"} />}>
                      <Dropdown.Item eventKey="1" icon={<Icon icon="profile"/>} componentClass={Link} to="/profile">Profile</Dropdown.Item>
                      <Dropdown.Item eventKey="2" icon={<Icon icon="people-group" />}>Buddies</Dropdown.Item>
                      <Dropdown.Item eventKey="3" icon={<Icon icon="suitcase" />}>Equipment</Dropdown.Item>
                      <Dropdown.Item divider/>
                      <Dropdown.Item eventKey="logout" icon={<Icon icon="sign-out" />} onSelect={userDropdown}>Log out</Dropdown.Item>
                    </Dropdown>
                  :
                    <Nav.Item eventKey="login" componentClass={Link} to="/login" icon={<Icon icon="sign-in" size={(narrow)?iconSize:"lg"} />}>{(narrow)?"":"Login"}</Nav.Item>
              }
              <Nav.Item icon={<Icon icon="cog" size={(narrow)?iconSize:"lg"}/>} componentClass={Link} to="/settings">{(narrow)?"":"Settings"}</Nav.Item>
            </Nav>
          </Navbar.Body>
      </Navbar>
    )}
    </CentralContext.Consumer>
  )
  /*
  return (
    <CentralContext.Consumer>
      {(context)=>(
        <div className={styles.navbar}>
          {(context.user.loggedIn)?<>
            <a href="/">Apnemo</a> | <input className={styles.mytextwithicon} type="text" name="search" placeholder="Search.."/>  | [<a href="/newsfeed">News Feed</a>] [<a href="/profile">{context.user.basic.firstName}</a>] [<NotificationsIcon width="16px" height="16px" />] [<button className={styles.btn} onClick={context.auth.logout}>Logout</button>]
          </>:<>
            <a href="/">Apnemo</a>
          </>}
        </div>
      )}
    </CentralContext.Consumer>
  );*/
}

/*
<div>ctx: {JSON.stringify(context)} |</div>

*/

export default NavBar
