import React, { Suspense } from 'react';
import {Router, Route, Switch } from "react-router-dom";
import CentralStore from "./context/centralStore.js";
import { Container, Header, Content, Footer, Sidebar } from 'rsuite';
import history from './utils/history';
//import authenticationService from './utils/services/authentication.service';
//import userService from './utils/services/user.service';

import PrivateRoute from "./utils/privateRoute";
import NavBar from "./components/navbar";
import screenSize from "./utils/hooks/screenSize";

//import logo from './logo.svg';
import styles from './App.module.css';
import 'rsuite/dist/styles/rsuite-default.css';

//Pages
const Login = React.lazy(()=>import("./pages/login"));
const Home = React.lazy(()=>import("./pages/home"));
const Register = React.lazy(()=>import("./pages/register"));
const NewsFeed = React.lazy(()=>import("./pages/newsfeed"));
const ProfilePage = React.lazy(()=>import("./pages/profile"));
const Locations = React.lazy(()=>import("./pages/locations"));
const Location = React.lazy(()=>import("./pages/locations/location"));
const NewLocation = React.lazy(()=>import("./pages/locations/newLocation"));
const Logbook = React.lazy(()=>import("./pages/logbook"));
const Dive = React.lazy(()=>import("./pages/logbook/dive"));
const Buddies = React.lazy(()=>import("./pages/buddies"));
const Support = React.lazy(()=>import("./pages/support"));
const Events = React.lazy(()=>import("./pages/events"));
const Equipment = React.lazy(()=>import("./pages/equipment"));
const Market = React.lazy(()=>import("./pages/market"));
const Manager = React.lazy(()=>import("./pages/manager"));
const Changes = React.lazy(()=>import("./pages/changes"));
const ResetPass = React.lazy(()=>import("./pages/resetpass"));
const SettingsPage = React.lazy(()=>import("./pages/settings"));

const App = props => {
  const { height, width, narrow } = screenSize();

  return (
    <CentralStore>
      <React.Suspense fallback={<p>Loading...</p>}>
        <Router history={history}>
          <Container>
            <Header>
              <NavBar />
            </Header>
            <Container>
              <Switch>
                <Route path="/" render={props => <Home {...props} />} exact/>
                <Route path="/home" render={props => <Home {...props} />} exact/>
                <Route path="/login" render={props => <Login {...props} />} />
                <Route path="/register" render={props => <Register {...props} />} />
                <Route path="/resetpass" render={props => <ResetPass {...props} />} />
                <PrivateRoute path="/newsfeed" component={NewsFeed} />
                <Route path="/locations" component={Locations} />
                <Route path="/location/:id" component={Location} />
                <Route path="/newlocation" component={NewLocation} />
                <Route path="/logbook" component={Logbook} />
                <Route path="/dive/:id" component={Dive} />
                <PrivateRoute path="/support" component={Support} />
                <PrivateRoute path="/buddies" component={Buddies} />
                <PrivateRoute path="/events" component={Events} />
                <PrivateRoute path="/equipment" component={Equipment} />
                <PrivateRoute path="/market" component={Market} />
                <PrivateRoute path="/manager" component={Manager} />
                <PrivateRoute path="/changes" component={Changes} />
                <PrivateRoute path="/settings" component={SettingsPage} />
                <Route path="/profile" component={ProfilePage} />
              </Switch>
            </Container>
          </Container>
        </Router>
      </React.Suspense>
    </CentralStore>
  )
  /*
  <ApnemoNav />

  <Route path="/register" render={props => <Register {...props} />} />
  <ProtectedRoute path="/dashboard" component={AuthHome} />
  <ProtectedRoute path="/locations" component={Locations} />
  <ProtectedRoute path="/events" component={Events} />
  <Route render={props => localStorage.getItem('jwt') ? <AuthHome {...props}/> : <Home {...props}/> }/>*/
}

export default App;
