import React, {useState} from 'react';
import authenticationService from '../utils/services/authentication.service';
//import * as Cookies from "js-cookie";
//import fetcher from "../utils/fetcher";

export const CentralContext = React.createContext();

const defaultUserValues = {
  basic: {
    firstName: "",
    lastName: "",
    email: ""
  },
  loggedIn: false,
  admin: false
}

const CentralStore = props => {
  const [user, setUser] = useLocalStorage("user", defaultUserValues);
  //console.log("from context", user);
  const [loggingIn, setLoggingIn] = useState(false);

  const login = (email, password, callback) =>{
    setLoggingIn(true);
    authenticationService.login(email, password)
    .then(
        userData => {
            console.log("logged in", userData);
            userData.loggedIn = true;
            setUser(userData);
            setLoggingIn(false);
            if(callback)callback(true);
        },
        error => {
            console.log("failed to log in", error);
            setLoggingIn(false);
            if(callback)callback(error);
        }
    )
    .catch(e=>{
      console.log("eee", e);
    })
  }

  const logout = ()=> {
    authenticationService.logout()
    .then(setUser(defaultUserValues))
  }

  return (
    <CentralContext.Provider value = {{
      auth: {
        login: login,
        logout: logout
      },
      user: user,
      loggingIn: loggingIn}}>
      {props.children}
    </CentralContext.Provider>
  )
}

// Hook

function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = value => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };
  return [storedValue, setValue];
}

export default CentralStore;
