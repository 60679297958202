import { handleResponse } from '../handleResponse';

const authenticationService = {
    login,
    logout,
    currentUser
};

function currentUser(){
  try {
    return JSON.parse(localStorage.getItem("user"));
  } catch (e) {
    return {
      loggedIn: false
    }
  }

}

function login(email, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password })
    };

    return fetch(`${process.env.REACT_APP_ROOT}/api/users/authenticate`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            user.loggedIn = true;

            localStorage.setItem('user', JSON.stringify(user));

            return user;
        }).catch(e=>{
          console.log("should return error");
          return Promise.reject(e);
        });
}

function logout() {
  console.log("logging out");
  // remove user from local storage to log user out
  localStorage.removeItem('user');
  return Promise.resolve();
}

export default authenticationService;
